.footer-logo {
	@include media('>=sm') {
		width: calc(25% + 35px);
		padding-right: 15px;
		padding-left: 15px;
	}
	@include media('<sm') {
		text-align: center;
	}
}

.footer-logo__link {
	@include media('<lg','>=sm') {
		max-width: 150px;
	}

	display: inline-block;

	vertical-align: top;
}

.footer-logo__image {
	display: block;

	max-width: 100%;
	height: auto;
}
