.social {
	@include media('<sm') {
		justify-content: center;
	}

	display: flex;
}

.social__item {
	&:nth-child(n+2) {
		@include media('>=sm') {
			margin-left: 20px;
		}
		@include media('<sm') {
			margin-left: 60px;
		}
	}
}

.social__link {
	display: block;

	text-decoration: none;

	color: white;
}

.social__icon {
	@include media('<lg','>=sm') {
		width: auto;
		height: 20px;
	}
	@include media('<sm') {
		width: auto;
		height: 40px;
	}

	display: block;

	fill: currentColor;
}
