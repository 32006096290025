.section {
	&_type_default {
		background: white;
	}
	&_type_team {
		color: white;
		background: #563ab0 url(../i/team.jpg) no-repeat 50% 50% / cover;
	}
}

.section__inner {
	@include media('>=lg') {
		padding: 90px 30px;
	}
	@include media('<lg') {
		padding-right: 20px;
		padding-left: 20px;
	}
	@include media('<lg','>=sm') {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	@include media('<sm') {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
}
