.header {
	@include media('>=lg') {
		left: calc(50% - 585px);

		width: 1170px;
		padding-top: 23px;
		padding-right: 15px;
		padding-left: 15px;
	}
	@include media('<lg') {
		left: 0;

		width: 100%;
		padding-right: 5px;
		padding-left: 5px;
	}
	@include media('<lg','>=sm') {
		padding-top: 15px;
	}
	@include media('>=sm') {
		align-items: center;
	}
	@include media('<sm') {
		padding-top: 23px;
	}

	position: absolute;
	top: 0;

	display: flex;

	justify-content: space-between;
}

.header__toggle {
	@include media('retina2x') {
		background-image: url(../i/header-toggle@2x.png);
	}
	@include media('>=sm') {
		display: none;
	}
	@include media('<sm') {
		display: block;
	}

	width: 40px;
	height: 40px;
	margin: 9px 15px 0;
	padding: 0;

	border: 0;
	outline: none;
	background: url(../i/header-toggle.png) no-repeat 0 0 / 40px 40px;
}

.header__menu {
	@include media('>=sm') {
		display: flex;

		width: 75%;
		padding-right: 15px;
		padding-left: 15px;

		align-items: center;
		justify-content: space-between;
	}
	@include media('<sm') {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;

		display: none;

		width: 100%;
		min-height: 100vh;
		padding-top: 120px;
		padding-bottom: 30px;

		background: #25154f url(../i/header-menu.jpg) no-repeat 50% 50% / cover;
	}

	.header_open & {
		@include media('<sm') {
			display: block;
		}
	}
}

.header__close {
	@include media('retina2x') {
		background-image: url(../i/header-close@2x.png);
	}
	@include media('>=sm') {
		display: none;
	}
	@include media('<sm') {
		display: block;
	}

	position: absolute;
	top: 32px;
	right: 20px;

	width: 40px;
	height: 40px;
	padding: 0;

	border: 0;
	outline: none;
	background: url(../i/header-close.png) no-repeat 50% 50% / 40px 40px;
}
