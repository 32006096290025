.btn {
	@include media('>=lg') {
		font-size: 22px;
		line-height: 1.37;

		padding: 15px 30px;
	}
	@include media('<lg','>=sm') {
		font-size: 16px;
		line-height: 1.25;

		padding: 10px 20px;
	}
	@include media('<sm') {
		font-size: 22px;
		line-height: 1.37;

		padding: 15px 20px;
	}

	font-weight: 800;

	display: inline-block;

	transition: background $t;
	text-align: center;

	color: white;
	border: 0;
	border-radius: 12px;
	outline: none;
	background: $green;
	body:not(.mobile) &:hover {
		background: $orange;
	}
}
