*,
:before,
:after {
	box-sizing: border-box;
}

html {
	@include media('>=lg') {
		font-size: 16px;
	}
	@include media('<lg','>=sm') {
		font-size: 12px;
	}
	@include media('<sm') {
		font-size: 14px;
	}

	font-family: $daxlinepro;
	font-weight: 300;
	line-height: 1.875;

	display: flex;

	min-height: 100%;

	letter-spacing: .2px;

	color: $black;
	background: white;
}

body {
	display: flex;
	flex-direction: column;

	width: 100%;
	min-width: 320px;
	min-height: 100vh;

	justify-content: space-between;
	&.mobile {
		cursor: pointer;

		-webkit-tap-highlight-color: transparent;
	}
}

a {
	transition: color $t;

	color: $green;

	body:not(.mobile) &:hover {
		color: $orange;
	}
}

h1 {
	@include media('>=lg') {
		font-size: 80px;
	}
	@include media('<lg','>=sm') {
		font-size: 55px;
	}
	@include media('<sm','>=xs') {
		font-size: 50px;
	}
	@include media('<xs') {
		font-size: 28px;
	}

	font-weight: 800;
	line-height: 1;

	position: relative;

	margin: 0;

	text-align: center;
}

h2 {
	@include media('>=lg') {
		font-size: 34px;
	}
	@include media('<lg','>=sm') {
		font-size: 24px;
	}
	@include media('<sm') {
		font-size: 30px;
	}

	font-weight: 300;
	line-height: 1;

	margin: 0;

	text-align: center;
}

h3 {
	@include media('>=lg') {
		@include margin-bottom(50px);
		@include margin-top(50px);

		font-size: 24px;
	}
	@include media('<lg','>=sm') {
		@include margin-bottom(40px);
		@include margin-top(40px);

		font-size: 16px;
	}
	@include media('<sm') {
		@include margin-bottom(20px);
		@include margin-top(20px);

		font-size: 24px;
	}

	font-weight: 800;
	line-height: 1.25;

	margin: 0;
}

figure {
	@include media('>=lg') {
		@include margin-bottom(50px);
		@include margin-top(50px);
	}
	@include media('<lg','>=sm') {
		@include margin-top(40px);
		@include margin-bottom(40px);
	}
	@include media('<sm') {
		@include margin-top(20px);
		@include margin-bottom(20px);
	}

	margin: 0;
	padding: 0;

	img {
		display: block;

		max-width: 100%;
		height: auto;

		border-radius: 12px;
	}
}

p {
	@include margin-bottom(1.875em);

	margin: 0;
}
