.services {
	@include media('>=lg') {
		@include margin-bottom(80px);
	}
	@include media('<lg','>=sm') {
		@include margin-bottom(60px);
	}
	@include media('<sm') {
		@include margin-bottom(40px);
	}
}

.services__item {
	@include media('>=lg') {
		@include margin-bottom(30px);

		padding: 50px 65px;
	}
	@include media('<lg') {
		@include margin-bottom(20px);
	}
	@include media('<lg','>=sm') {
		padding: 40px 45px;
	}
	@include media('>=sm') {
		display: flex;
	}
	@include media('<sm') {
		@include margin-bottom(20px);

		padding: 40px 20px;
	}

	border-radius: 12px;
	background: white;
	box-shadow: 0 10px 55px rgba(168,14,239,.1);
}

.services__hero {
	@include media('>=lg') {
		width: 220px;
		padding-top: 15px;
	}
	@include media('<lg','>=sm') {
		width: 150px;
		padding-top: 5px;
	}

	.services__item:nth-child(2n) & {
		@include media('>=sm') {
			order: 1;
		}
	}
}

.services__image {
	display: block;

	max-width: 100%;
	height: auto;
	margin-right: auto;
	margin-left: auto;
}

.services__body {
	@include media('>=lg') {
		width: calc(100% - 220px);
	}
	@include media('<lg','>=sm') {
		width: calc(100% - 150px);
	}
	@include media('<sm') {
		margin-top: 20px;

		text-align: center;
	}

	.services__item:nth-child(2n+1) & {
		@include media('>=lg') {
			padding-left: 65px;
		}
		@include media('<lg','>=sm') {
			padding-left: 45px;
		}
	}
	.services__item:nth-child(2n) & {
		@include media('>=lg') {
			padding-right: 65px;
		}
		@include media('<lg','>=sm') {
			padding-right: 45px;
		}
	}
}
