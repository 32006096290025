.experience {
	@include media('>=lg') {
		@include margin-top(80px);
		@include margin-bottom(80px);

		font-size: 22px;
		line-height: 1.46;

		max-width: 920px;
		padding: 40px 30px;
	}
	@include media('<lg') {
		font-size: 16px;
		line-height: 1.375;

		max-width: 660px;
		padding: 30px 20px;
	}
	@include media('<lg','>=sm') {
		@include margin-top(60px);
		@include margin-bottom(60px);
	}
	@include media('<sm') {
		@include margin-top(40px);
		@include margin-bottom(40px);
	}

	font-weight: 800;

	margin-right: auto;
	margin-left: auto;

	text-align: center;

	color: white;
	border-radius: 12px;
	background: #947ed4 url(../i/experience.jpg) no-repeat 50% 50% / cover;
}
