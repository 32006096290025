.intro {
	@include media('>=lg') {
		height: 800px;
	}
	@include media('<lg','>=sm') {
		height: 555px;
	}
	@include media('<sm') {
		min-height: 100vh;
		padding-top: 100px;
		padding-bottom: 100px;
	}

	position: relative;

	display: flex;
	flex-direction: column;

	color: white;
	background: #23154a url(../i/intro.jpg) no-repeat 50% 50% / cover;

	align-items: center;
	justify-content: center;
}

.intro__body {
	@include media('>=lg') {
		padding: 0 30px;
	}
	@include media('<lg','>=xs') {
		padding: 0 20px;
	}
	@include media('<xs') {
		padding: 0 10px;
	}

	position: relative;
}

.intro__title {
	@include media('>=lg') {
		padding-right: 175px;
		padding-left: 175px;
	}
	@include media('<lg','>=sm') {
		padding-right: 120px;
		padding-left: 120px;
	}
	@include media('<sm','>=xs') {
		padding-right: 110px;
		padding-left: 110px;
	}

	position: relative;
	z-index: 1;

	max-width: 1070px;
	margin-right: auto;
	margin-left: auto;


	&::before,
	&::after {
		@include media('retina2x') {
			background-image: url(../i/quotes@2x.png);
		}
		@include media('>=lg') {
			width: 175px;
			height: 163px;

			background-size: 175px 326px;
		}
		@include media('<lg','>=sm') {
			width: 120px;
			height: 112px;

			background-size: 120px 224px;
		}
		@include media('<sm','>=xs') {
			width: 110px;
			height: 102px;

			background-size: 110px 204px;
		}
		@include media('<xs') {
			display: none;
		}

		position: absolute;
		top: 50%;

		display: block;

		content: '';
		transform: translateY(-50%);

		background-image: url(../i/quotes.png);
		background-repeat: no-repeat;
	}
	&::before {
		@include media('>=lg') {
			left: 90px;
		}
		@include media('<lg','>=sm') {
			left: 62px;
		}
		@include media('<sm','>=xs') {
			left: 56px;
		}
		@include media('<xs') {
			left: 30px;
		}

		background-position: 0 0;
	}
	&::after {
		right: 0;

		background-position: 0 100%;
	}
}

.intro__text {
	@include media('>=lg') {
		font-size: 20px;
		line-height: 1.8;

		left: calc(50% - 270px);

		width: 540px;
	}
	@include media('<lg') {
		font-size: 16px;
		line-height: 1.5;
	}
	@include media('<lg','>=sm') {
		left: calc(50% - 170px);

		width: 340px;
	}
	@include media('>=sm') {
		position: absolute;
		top: calc(100% + 20px);
	}
	@include media('<sm') {
		max-width: 340px;
		margin-top: 20px;
		margin-right: auto;
		margin-left: auto;
	}

	font-weight: 400;

	text-align: center;
}
