.logo {
	@include media('>=sm') {
		width: 25%;
	}
	@include media('<sm') {
		z-index: 3;
	}

	position: relative;

	padding: 0 15px;
}

.logo__link {
	@include media('<lg','>=sm') {
		max-width: 150px;
	}

	display: inline-block;

	vertical-align: top;
}

.logo__image {
	display: block;

	max-width: 100%;
	height: auto;
}
