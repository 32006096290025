.footer {
	color: white;
	background: #4824ad;
}

.footer__inner {
	@include media('>=lg') {
		padding: 50px 15px;
	}
	@include media('<lg','>=sm') {
		padding: 30px 5px;
	}
	@include media('>=sm') {
		display: flex;
	}
	@include media('<sm') {
		padding: 40px 20px;
	}

	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
}
