.feedback {
	@include media('>=lg') {
		@include margin-bottom(80px);
	}
	@include media('<lg','>=sm') {
		@include margin-bottom(60px);
	}
	@include media('<sm') {
		@include margin-bottom(40px);
	}
}

.feedback__caption {
	@include media('>=lg') {
		margin-bottom: 50px;
	}
	@include media('<lg','>=sm') {
		margin-bottom: 40px;
	}
	@include media('<sm') {
		margin-bottom: 20px;
	}
}

.feedback__columns {
	@include media('>=lg') {
		@include margin-bottom(30px);

		margin-right: -15px;
		margin-left: -15px;
	}
	@include media('<lg') {
		@include margin-bottom(20px);
	}
	@include media('<lg','>=sm') {
		margin-right: -10px;
		margin-left: -10px;
	}
	@include media('>=sm') {
		display: flex;
	}
}

.feedback__column {
	@include media('>=lg') {
		width: calc(33.3333% - 30px);
		margin-right: 15px;
		margin-left: 15px;
	}
	@include media('<lg','>=sm') {
		width: calc(33.3333% - 20px);
		margin-right: 10px;
		margin-left: 10px;
	}
	@include media('<sm') {
		@include margin-bottom(20px);
	}
}

.feedback__group {
	@include media('>=lg') {
		@include margin-bottom(30px);
	}
	@include media('<lg') {
		@include margin-bottom(20px);
	}

	position: relative;
}

.feedback__btn {
	@include media('>=lg') {
		width: calc(33.3333% - 20px);
	}
	@include media('<lg','>=sm') {
		width: calc(33.3333% - 13px);
	}
	@include media('<sm') {
		width: 100%;
	}
}
