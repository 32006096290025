.dev {
	@include margin-top(30px);
	@include media('>=lg') {
		font-size: 14px;
		line-height: 1.72;
	}
	@include media('<sm') {
		text-align: center;
	}
}
