.footer-nav {
	@include media('>=sm') {
		width: 25%;
		padding-right: 15px;
		padding-left: 15px;
	}
	@include media('<sm') {
		margin-top: 40px;
	}
}

.footer-nav__list {
	@include reset-list;
	@include media('>=lg') {
		font-size: 14px;
		line-height: 1.72;
	}
	@include media('<sm') {
		font-size: 18px;
		line-height: 2.12;

		text-align: center;
	}
}

.footer-nav__item {
	&_active {
		font-weight: 800;
	}
}

.footer-nav__link {
	text-decoration: none;

	color: white;

	.footer-nav__item_active & {
		color: $orange;
	}
}
