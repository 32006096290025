.platform {
	@include media('>=lg') {
		@include margin-top(80px);
		@include margin-bottom(80px);
	}
	@include media('<lg','>=sm') {
		@include margin-top(60px);
		@include margin-bottom(60px);
	}
	@include media('<sm') {
		@include margin-top(40px);
		@include margin-bottom(40px);
	}
}
