.example {
	@include media('>=lg') {
		@include margin-top(80px);
		@include margin-bottom(80px);
	}
	@include media('<lg','>=sm') {
		@include margin-top(60px);
		@include margin-bottom(60px);
	}
	@include media('<sm') {
		@include margin-top(40px);
		@include margin-bottom(40px);
	}
}

.example__code {
	@include media('retina2x') {
		background-image: url(../i/code-buttons@2x.png);
	}
	@include media('>=lg') {
		@include margin-bottom(50px);
		@include margin-top(50px);

		padding-top: 72px;

		background-position: 24px 28px;
		background-size: auto 16px;
	}
	@include media('<lg','>=sm') {
		@include margin-top(40px);
		@include margin-bottom(40px);

		padding-top: 50px;

		background-position: 18px 19px;
		background-size: auto 12px;
	}
	@include media('<sm') {
		@include margin-top(20px);
		@include margin-bottom(20px);

		padding-top: 46px;

		background-position: 20px 18px;
		background-size: auto 10px;
	}

	font-size: 11px;
	line-height: 1.5;

	border-radius: 12px 12px 0 0;
	background-color: #f1f1f1;
	background-image: url(../i/code-buttons.png);
	background-repeat: no-repeat;


	pre {
		@include media('>=lg') {
			padding: 30px 65px;
		}
		@include media('<lg','>=sm') {
			padding: 30px 45px;
		}
		@include media('<sm') {
			padding: 20px;
		}

		display: block;

		margin: 0;

		border-radius: 0 0 12px 12px;
	}
}
