.heading {
	@include media('>=lg') {
		@include margin-bottom(50px);

		max-width: 640px;
		padding: 0 100px;
	}
	@include media('<lg','>=sm') {
		@include margin-bottom(30px);

		max-width: 440px;
		padding: 0 70px;
	}
	@include media('<sm') {
		@include margin-bottom(40px);

		padding-top: 25px;
	}

	position: relative;

	margin-right: auto;
	margin-left: auto;
}

.heading__line {
	@include media('>=lg') {
		width: 90px;
	}
	@include media('<lg','>=sm') {
		width: 60px;
	}
	@include media('>=sm') {
		top: calc(50% - 1px);
	}
	@include media('<sm') {
		top: 2px;

		width: 90px;
	}

	position: absolute;

	display: block;

	height: 2px;

	background: linear-gradient(to right, $green, $orange);
	&::before,
	&::after {
		position: absolute;
		top: -2px;

		display: block;

		width: 6px;
		height: 6px;

		content: '';

		border-radius: 50%;
	}
	&::before {
		left: -3px;

		background: $green;
	}
	&::after {
		right: -3px;

		background: $orange;
	}
	&_left {
		@include media('>=sm') {
			left: 3px;
		}
		@include media('<sm') {
			left: calc(50% - 45px);
		}
	}
	&_right {
		@include media('<sm') {
			display: none;
		}

		right: 3px;
	}
}
