.about-text {
	@include media('>=lg') {
		max-width: 680px;
	}
	@include media('<lg') {
		max-width: 500px;
	}

	margin: 0 auto;

	text-align: center;
}
