@font-face {
	font-family: 'DaxlinePro';
	font-weight: 100;
	font-style: normal;

	src: url('../fonts/DaxlinePro/DaxlinePro-Thin.woff2') format('woff2'),
	url('../fonts/DaxlinePro/DaxlinePro-Thin.woff') format('woff');
}

@font-face {
	font-family: 'DaxlinePro';
	font-weight: 300;
	font-style: normal;

	src: url('../fonts/DaxlinePro/DaxlinePro-Light.woff2') format('woff2'),
	url('../fonts/DaxlinePro/DaxlinePro-Light.woff') format('woff');
}

@font-face {
	font-family: 'DaxlinePro';
	font-weight: 400;
	font-style: normal;

	src: url('../fonts/DaxlinePro/DaxlinePro-Regular.woff2') format('woff2'),
	url('../fonts/DaxlinePro/DaxlinePro-Regular.woff') format('woff');
}

@font-face {
	font-family: 'DaxlinePro';
	font-weight: 500;
	font-style: normal;

	src: url('../fonts/DaxlinePro/DaxlinePro-Medium.woff2') format('woff2'),
	url('../fonts/DaxlinePro/DaxlinePro-Medium.woff') format('woff');
}

@font-face {
	font-family: 'DaxlinePro';
	font-weight: 700;
	font-style: normal;

	src: url('../fonts/DaxlinePro/DaxlinePro-Bold.woff2') format('woff2'),
	url('../fonts/DaxlinePro/DaxlinePro-Bold.woff') format('woff');
}

@font-face {
	font-family: 'DaxlinePro';
	font-weight: 800;
	font-style: normal;

	src: url('../fonts/DaxlinePro/DaxlinePro-ExtraBold.woff2') format('woff2'),
	url('../fonts/DaxlinePro/DaxlinePro-ExtraBold.woff') format('woff');
}

@font-face {
	font-family: 'DaxlinePro';
	font-weight: 900;
	font-style: normal;

	src: url('../fonts/DaxlinePro/DaxlinePro-Black.woff2') format('woff2'),
	url('../fonts/DaxlinePro/DaxlinePro-Black.woff') format('woff');
}
