.footer-social {
	@include media('>=sm') {
		width: 25%;
		padding-right: 15px;
		padding-left: 15px;
	}
	@include media('<sm') {
		margin-top: 40px;
	}
}

.footer-social__caption {
	@include media('>=lg') {
		font-size: 14px;
		line-height: 1.72;

		margin-bottom: 20px;
	}
	@include media('<lg') {
		margin-bottom: 15px;
	}
	@include media('<sm') {
		font-size: 18px;
		line-height: 2.23;

		text-align: center;
	}
}
