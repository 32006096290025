@charset 'UTF-8';

// Fonts
$daxlinepro: DaxlinePro, sans-serif;

// Colors
$black: #1a171b;
$green: #62c422;
$orange: #ff5f00;

// Transition
$t: .2s ease;

// Breakpoints
$breakpoints: (
	'xs': 568px,
	'sm': 768px,
	'md': 1024px,
	'lg': 1170px
);
