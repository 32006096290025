.details {
	@include media('>=lg') {
		@include margin-bottom(50px);
		@include margin-top(50px);

		margin-right: -15px;
		margin-left: -15px;
	}
	@include media('<lg') {
		margin-right: -10px;
		margin-left: -10px;
	}
	@include media('<lg','>=sm') {
		@include margin-top(40px);
		@include margin-bottom(40px);
	}
	@include media('<sm') {
		@include margin-top(20px);
		@include margin-bottom(20px);
	}

	display: flex;

	flex-wrap: wrap;
}

.details__item {
	@include media('>=lg') {
		width: calc(33.3333% - 30px);
		margin-right: 15px;
		margin-left: 15px;
		padding-left: 65px;
	}
	@include media('<lg') {
		margin-right: 10px;
		margin-left: 10px;
	}
	@include media('<lg','>=sm') {
		width: calc(33.3333% - 20px);
		padding-left: 45px;
	}
	@include media('<sm') {
		@include margin-bottom(20px);

		width: calc(100% - 20px);
		padding-left: 60px;
	}

	&::before {
		@include media('retina2x') {
			background-image: url(../i/details@2x.png);
		}
		@include media('>=lg') {
			width: 46px;
			height: 50px;
			margin-left: -65px;

			background-size: 46px auto;
		}
		@include media('<lg','>=sm') {
			width: 32px;
			height: 34px;
			margin-left: -45px;

			background-size: 32px auto;
		}
		@include media('<sm') {
			width: 46px;
			height: 50px;
			margin-left: -60px;

			background-size: 46px auto;
		}

		float: left;

		content: '';

		background-image: url(../i/details.png);
		background-repeat: no-repeat;
	}
	&_type_advantages::before {
		background-position: 0 0;
	}
	&_type_disadvantages::before {
		background-position: 0 50%;
	}
	&_type_languages::before {
		background-position: 0 100%;
	}
}

.details__caption {
	@include media('>=lg') {
		padding: 10px 0;
	}
	@include media('<lg','>=sm') {
		padding: 5px 0;
	}
	@include media('<sm') {
		font-size: 16px;

		padding: 10px 0;
	}

	font-weight: 800;

	margin-bottom: 15px;
}

.details__text {
	line-height: 1.5;
}
