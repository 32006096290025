.parsley-errors-list {
	@include reset-list;
	@include media('>=lg') {
		font-size: 16px;
		line-height: 1.875;

		padding: 15px 30px;
	}
	@include media('<lg','>=sm') {
		font-size: 12px;
		line-height: 1.67;

		padding: 8px 20px;
	}
	@include media('<sm') {
		font-size: 16px;
		line-height: 1.875;

		padding: 15px 30px;
	}

	position: absolute;
	z-index: 1;
	top: calc(100% + 14px);
	left: 0;

	display: none;

	width: 100%;

	text-align: center;

	color: white;
	border-radius: 12px;
	background: $orange;
	&.filled {
		display: block;
	}

	&::before {
		@include triangle(20px 10px, $orange, up);

		position: absolute;
		bottom: 100%;
		left: calc(50% - 10px);

		content: '';
	}
}
