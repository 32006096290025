.nav {
	@include media('<sm') {
		@include margin-bottom(50px);

		padding-right: 20px;
		padding-left: 20px;
	}
}

.nav__list {
	@include reset-list;
	@include media('>=lg') {
		font-size: 14px;
	}
	@include media('<lg','>=sm') {
		font-size: 12px;
	}
	@include media('>=sm') {
		line-height: 2.15;

		margin-right: -20px;
		margin-left: -20px;

		flex-wrap: wrap;
	}
	@include media('<sm') {
		font-size: 30px;
		line-height: 1.5;

		flex-direction: column;

		text-align: center;

		align-items: center;
	}

	display: flex;

	letter-spacing: 1px;
}

.nav__item {
	@include media('>=md') {
		margin-right: 20px;
		margin-left: 20px;
	}
	@include media('<md','>=sm') {
		margin-right: 14px;
		margin-left: 14px;
	}
	@include media('<sm') {
		@include margin-bottom(25px);
	}

	position: relative;

	&::before {
		position: absolute;
		bottom: 2px;
		left: 0;

		display: block;

		width: 100%;
		height: 2px;

		content: '';
		transition: opacity $t;

		opacity: 0;
		background: linear-gradient(to right, $green, $orange);
	}

	body:not(.mobile) &:hover::before,
	&_active::before {
		opacity: 1;
	}
	&_active {
		font-weight: 800;
	}
}

.nav__link {
	position: relative;

	display: block;

	padding: 4px 0;

	text-decoration: none;

	color: white;

	&::before,
	&::after {
		position: absolute;
		bottom: 0;

		width: 6px;
		height: 6px;

		content: '';
		transition: opacity $t;

		opacity: 0;
		border-radius: 50%;
	}
	&::before {
		left: -3px;

		background: $green;
	}
	&::after {
		right: -3px;

		background: $orange;
	}
	body:not(.mobile) .nav__item:hover &,
	.nav__item_active & {
		color: $orange;

		&::before,
		&::after {
			opacity: 1;
		}
	}
}
