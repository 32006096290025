.text {
	@include media('>=lg') {
		@include margin-bottom(50px);
		@include margin-top(50px);
	}
	@include media('<lg','>=sm') {
		@include margin-top(40px);
		@include margin-bottom(40px);
	}
	@include media('<sm') {
		@include margin-top(20px);
		@include margin-bottom(20px);
	}
	ul {
		@include margin-bottom(1.875em);

		margin: 0;
		padding: 0;

		list-style: none;

		& > li {
			@include media('>=lg') {
				margin-left: 32px;
			}
			@include media('<lg','>=sm') {
				margin-left: 22px;
			}
			@include media('<sm') {
				margin-left: 20px;
			}

			&::before {
				@include media('>=lg') {
					margin: 11px 0 0 -32px;
				}
				@include media('<lg','>=sm') {
					margin: 7px 0 0 -22px;
				}
				@include media('<sm') {
					margin: 9px 0 0 -20px;
				}

				float: left;

				width: 6px;
				height: 6px;

				content: '';

				border-radius: 50%;
				background: $orange;
			}
		}
	}
	ol {
		@include margin-bottom(1.875em);

		margin: 0 0 0 32px;
		padding: 0;
	}
}
