.team {
	@include media('>=lg') {
		margin: -30px -15px 0;
	}
	@include media('<lg') {
		margin-top: -20px;
	}
	@include media('<lg','>=xs') {
		margin-right: -10px;
		margin-left: -10px;
	}
	@include media('<xs') {
		margin-right: -20px;
		margin-left: -20px;
	}

	display: flex;

	flex-wrap: wrap;
}

.team__item {
	@include media('>=lg') {
		width: calc(25% - 30px);
		margin: 30px 15px 0;
	}
	@include media('<lg') {
		margin-top: 20px;
		margin-right: 10px;
		margin-left: 10px;
	}
	@include media('<lg','>=sm') {
		width: calc(25% - 20px);
	}
	@include media('<sm','>=xs') {
		width: calc(33.3333% - 20px);
	}
	@include media('<xs') {
		width: calc(50% - 20px);
	}
}

.team__hero {
	position: relative;

	max-width: 254px;
	margin: 0 auto 5px;

	background: url(../i/team-hero.png) no-repeat 50% 50% / contain;
	&::before {
		display: block;

		width: 100%;
		padding-top: 91.338%;

		content: '';
	}
	&::after {
		@include media('retina2x') {
			background-image: url(../i/team-circle@2x.png);
		}

		position: absolute;
		top: 50%;
		left: 50%;

		display: block;

		width: 78.74%;
		height: 86.207%;

		content: '';
		transform: translate(-50%, -50%);

		background: url(../i/team-circle.png) no-repeat 50% 50% / contain;
	}
}

.team__image {
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;

	display: block;

	width: 68.5%;
	height: auto;

	transform: translate(-50%, -50%);

	border-radius: 50%;
}

.team__name {
	@include media('>=lg') {
		font-size: 18px;
		line-height: 1.67;
	}
	@include media('<lg','>=sm') {
		font-size: 14px;
		line-height: 1.43;
	}
	@include media('<sm') {
		font-size: 16px;
		line-height: 1.25;
	}

	font-weight: 800;

	text-align: center;
}

.team__whois {
	@include media('>=lg') {
		line-height: 2.5;
	}
	@include media('<lg') {
		line-height: 1.67;
	}

	font-size: 12px;
	font-weight: 400;

	text-align: center;

	color: $orange;

	&::before {
		margin-right: .25em;

		content: '\003C';

		color: $green;
	}
	&::after {
		margin-left: .25em;

		content: '\003E';

		color: $green;
	}
}
