.entry {
	@include media('>=lg') {
		font-size: 16px;
		line-height: 1.875;

		height: 60px;
		padding: 14px 29px;
	}
	@include media('<lg','>=sm') {
		font-size: 12px;
		line-height: 1.67;

		height: 40px;
		padding: 9px 19px;
	}
	@include media('<sm') {
		font-size: 16px;
		line-height: 1.875;

		height: 60px;
		padding: 14px 19px;
	}

	display: block;

	width: 100%;

	transition: box-shadow $t, border-color $t;

	color: $black;
	border: 1px solid white;
	border-radius: 12px;
	outline: none;
	background: white;
	box-shadow: 0 10px 55px rgba(168,14,239,.1);

	&::placeholder {
		opacity: 1;
		color: $black;
	}

	&.parsley-error {
		border-color: $orange;
		box-shadow: 0 10px 55px rgba(168,14,239,.3);
	}
}
