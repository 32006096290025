.about {
	@include media('>=lg') {
		margin-top: 20px;
		margin-right: -15px;
		margin-left: -15px;
	}
	@include media('<lg') {
		margin-top: 10px;
		margin-right: -10px;
		margin-left: -10px;
	}

	display: flex;

	flex-wrap: wrap;
}

.about__item {
	@include media('>=lg') {
		width: calc(33.3333% - 30px);
		margin-top: 30px;
		margin-right: 15px;
		margin-left: 15px;
		padding: 40px;
	}
	@include media('<lg') {
		margin-top: 20px;
		margin-right: 10px;
		margin-left: 10px;
	}
	@include media('<lg','>=sm') {
		width: calc(33.3333% - 20px);
		padding: 25px 20px;
	}
	@include media('<sm') {
		padding: 40px 20px;
	}
	@include media('<sm','>=xs') {
		width: calc(50% - 20px);
	}
	@include media('<xs') {
		width: calc(100% - 20px);
	}

	border-radius: 12px;
	box-shadow: 0 10px 55px rgba(168,14,239,.1);
}

.about__hero {
	@include media('>=lg') {
		margin-bottom: 30px;
	}
	@include media('<lg') {
		margin-bottom: 20px;
	}

	max-width: 210px;
	margin-right: auto;
	margin-left: auto;
}

.about__image {
	display: block;

	max-width: 100%;
	height: auto;
	margin-right: auto;
	margin-left: auto;
}

.about__caption {
	@include media('>=lg') {
		font-size: 24px;
	}
	@include media('<lg','>=sm') {
		font-size: 18px;
	}
	@include media('<sm') {
		font-size: 24px;
	}

	font-weight: 800;
	line-height: 1.25;

	text-align: center;
}

.about__text {
	@include media('>=lg') {
		font-size: 14px;
		line-height: 1.72;

		margin-top: 15px;
	}
	@include media('<lg','>=sm') {
		margin-top: 10px;
	}
	@include media('<sm') {
		font-size: 14px;
		line-height: 1.72;

		margin-top: 15px;
	}

	text-align: center;
}
