.siteinfo {
	@include media('>=sm') {
		display: flex;
		flex-direction: column;

		width: calc(25% - 35px);
		padding-right: 15px;
		padding-left: 15px;

		justify-content: space-between;
	}
	@include media('<sm') {
		margin-top: 40px;
	}
}
