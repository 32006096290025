.header-social {
	@include media('<sm') {
		padding-right: 20px;
		padding-left: 20px;
	}
}

.header-social__caption {
	@include media('>=sm') {
		display: none;
	}

	font-size: 18px;

	margin-bottom: 15px;

	text-align: center;
}
