.faq {
	@include media('>=lg') {
		@include margin-bottom(50px);
	}
	@include media('<lg','>=sm') {
		@include margin-bottom(30px);
	}
	@include media('<sm') {
		@include margin-bottom(40px);
	}
}

.faq__item {
	@include media('>=lg') {
		@include margin-bottom(30px);
	}
	@include media('<lg') {
		@include margin-bottom(20px);
	}

	position: relative;

	transition: box-shadow $t;

	border-radius: 12px;
	background: white;
	box-shadow: 0 10px 55px rgba(168,14,239,.1);

	&_visible {
		box-shadow: 0 10px 55px rgba(168,14,239,.3);
	}
}

.faq__header {
	@include media('>=lg') {
		padding: 30px 65px;
	}
	@include media('<lg','>=sm') {
		padding: 20px 45px;
	}
	@include media('<sm') {
		padding: 30px 20px;
	}

	display: flex;

	cursor: pointer;

	border-radius: 12px;

	align-items: flex-start;
	justify-content: space-between;
}

.faq__caption {
	@include media('>=lg') {
		font-size: 22px;
		line-height: 1.37;
	}
	@include media('<lg','>=sm') {
		font-size: 16px;
		line-height: 1.25;
	}
	@include media('<sm') {
		font-size: 18px;
		line-height: 1.67;
	}

	font-weight: 800;
}

.faq__btn {
	@include media('>=lg') {
		width: 24px;
		height: 24px;
		margin: 3px 0 0 30px;
	}
	@include media('<lg','>=sm') {
		width: 18px;
		height: 18px;
		margin: 1px 0 0 20px;
	}
	@include media('<sm') {
		width: 24px;
		height: 24px;
		margin: 3px 0 0 20px;
	}

	padding: 0;

	cursor: pointer;
	transition: all $t;

	color: $green;
	border: 0;
	border-radius: 0;
	outline: none;
	background: none;

	body:not(.mobile) &:hover {
		color: $orange;
	}
	.faq__item_active & {
		transform: rotate(180deg);
	}
}

.faq__btn-icon {
	width: 100%;
	height: auto;

	fill: currentColor;
}

.faq__body {
	display: none;
	overflow: hidden;

	transition: height $t;

	border-radius: 0 0 12px 12px;

	.faq__item_active & {
		position: absolute;

		display: block;

		width: 100%;
	}
	.faq__item_visible & {
		position: relative;

		width: auto;
	}
}

.faq__text {
	@include media('>=lg') {
		font-size: 14px;
		line-height: 1.72;

		padding: 0 65px 50px;
	}
	@include media('<lg','>=sm') {
		padding: 0 45px 30px;
	}
	@include media('<sm') {
		padding: 0 20px 40px;
	}

	border-radius: 0 0 12px 12px;
}
